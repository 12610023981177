import React from "react";
import { Feelings, Feeling, Layout } from "../../components";
import feelings from "../../shared/feelings.json";
import Color from "color";
import { Row, Col } from "reactstrap";
import { useSelectedFeelings } from "../../shared/hooks/use-selected-feelings";
import find from "lodash/find";
import reject from "lodash/reject";

const Explore = () => {
  const { selectedFeelings, setSelectedFeelings } = useSelectedFeelings();

  const isSelected = (name: string) =>
    !!find(selectedFeelings as any, { name });

  const toggleSelection = (name: string, color: string) => {
    if (isSelected(name)) {
      setSelectedFeelings((prev) => {
        return [...reject(prev, { name })];
      });
    } else {
      setSelectedFeelings((prev) => [...prev, { name, color }]);
    }
  };

  const renderFeelings = (currentFeelings: any, customColor?: any) => {
    return currentFeelings.map(({ name, color, children }: any) => {
      const baseColor = Color(customColor || color);
      return (
        <React.Fragment key={name}>
          <Feeling
            baseColor={baseColor.toString()}
            isSelected={isSelected(name)}
            onClick={() => toggleSelection(name, baseColor.toString())}
          >
            {name}
          </Feeling>
          {children && renderFeelings(children, baseColor)}
        </React.Fragment>
      );
    });
  };

  return (
    <Layout>
      <Row className="mt-5">
        <Col>
          <Feelings>{renderFeelings(feelings)}</Feelings>
        </Col>
      </Row>
    </Layout>
  );
};

export default Explore;
