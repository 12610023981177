import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import feelings from "../../shared/feelings.json";
import styles from "./identify.module.scss";
import isEmpty from "lodash/isEmpty";
import pullAt from "lodash/pullAt";
import { Feelings, Feeling, Layout } from "../../components";
import { useSelectedFeelings } from "../../shared/hooks/use-selected-feelings";

const Identify = () => {
  const { selectedFeelings, setSelectedFeelings } = useSelectedFeelings();

  const [rootFeelingIndex, setRootFeelingIndex] = useState<
    number | undefined
  >();
  const [secondaryFeelingIndex, setSecondaryFeelingIndex] = useState<
    number | undefined
  >();
  const [tertiaryFeelingIndex, setTertiaryFeelingIndex] = useState<
    number | undefined
  >();

  const rootFeeling =
    (rootFeelingIndex !== undefined && feelings[rootFeelingIndex]) || undefined;
  const secondaryFeeling =
    (rootFeeling &&
      secondaryFeelingIndex !== undefined &&
      feelings[rootFeelingIndex!].children[secondaryFeelingIndex]) ||
    undefined;
  const tertiaryFeeling =
    (rootFeeling &&
      secondaryFeeling &&
      tertiaryFeelingIndex !== undefined &&
      feelings[rootFeelingIndex!].children[secondaryFeelingIndex!].children[
        tertiaryFeelingIndex
      ]) ||
    undefined;

  const handleRootFeelingChange = (i: number | undefined) => {
    setTertiaryFeelingIndex(undefined);
    setSecondaryFeelingIndex(undefined);
    setRootFeelingIndex((prev) => (prev === i ? undefined : i));
  };

  const handleSecondaryFeelingChange = (i: number) => {
    setTertiaryFeelingIndex(undefined);
    setSecondaryFeelingIndex((prev) => (prev === i ? undefined : i));
  };

  const handleTertiaryFeelingChange = (i: number) => {
    setTertiaryFeelingIndex((prev) => (prev === i ? undefined : i));
  };

  const handleSaveFeelings = () => {
    setSelectedFeelings((prev) => [
      ...prev,
      {
        color: rootFeeling!.color,
        name:
          tertiaryFeeling?.name || secondaryFeeling?.name || rootFeeling!.name,
      },
    ]);
    handleRootFeelingChange(undefined);
  };

  const handleRemoveFeeling = (i: number) => {
    setSelectedFeelings((prev) => {
      pullAt(prev, i);
      return [...prev];
    });
  };

  return (
    <Layout className={styles.feelingsContainer}>
      {!isEmpty(selectedFeelings) && (
        <Row>
          <Col className="mt-5">
            <Feelings>
              {selectedFeelings.map(({ color, name }, i) => {
                return (
                  <Feeling
                    key={`saved${name}`}
                    isSelected={true}
                    baseColor={color}
                    onClick={() => handleRemoveFeeling(i)}
                  >
                    {name}
                  </Feeling>
                );
              })}
            </Feelings>
          </Col>
        </Row>
      )}
      <Row className="mt-5">
        <Col>
          <Row>
            <Col>
              <h3>ADD A MOOD</h3>
              <Feelings>
                {feelings.map(({ name, color }, i) => {
                  const isSelected = rootFeelingIndex === i;
                  if (rootFeelingIndex !== undefined && !isSelected)
                    return null;
                  return (
                    <Feeling
                      key={name}
                      isSelected={isSelected}
                      baseColor={color}
                      onClick={() => handleRootFeelingChange(i)}
                    >
                      {name}
                    </Feeling>
                  );
                })}
              </Feelings>
            </Col>
          </Row>
          {rootFeeling && (
            <Row>
              <Col>
                <Feelings>
                  {rootFeeling.children.map(({ name }, i) => {
                    const isSelected = secondaryFeelingIndex === i;
                    if (secondaryFeelingIndex !== undefined && !isSelected)
                      return null;
                    return (
                      <Feeling
                        key={name}
                        isSelected={isSelected}
                        baseColor={rootFeeling.color}
                        onClick={() => handleSecondaryFeelingChange(i)}
                      >
                        {name}
                      </Feeling>
                    );
                  })}
                </Feelings>
              </Col>
            </Row>
          )}
          {rootFeeling && secondaryFeeling && (
            <Row>
              <Col>
                <Feelings>
                  {secondaryFeeling.children.map(({ name }, i) => {
                    const isSelected = tertiaryFeelingIndex === i;
                    if (tertiaryFeelingIndex !== undefined && !isSelected)
                      return null;
                    return (
                      <Feeling
                        key={name}
                        isSelected={isSelected}
                        baseColor={rootFeeling.color}
                        onClick={() => handleTertiaryFeelingChange(i)}
                      >
                        {name}
                      </Feeling>
                    );
                  })}
                </Feelings>
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col>
              <Button
                color="info"
                onClick={handleSaveFeelings}
                disabled={!rootFeeling}
              >
                + Add
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default Identify;
