import React, { useState, createContext, useContext } from "react";

interface SelectedFeeling {
  color: string;
  name: string;
}

interface Context {
  selectedFeelings: SelectedFeeling[];
  setSelectedFeelings: React.Dispatch<React.SetStateAction<SelectedFeeling[]>>;
}

const defaultContext: Context = {
  selectedFeelings: [],
  setSelectedFeelings: () => [],
};

const SelectedFeelingsContext = createContext(defaultContext);

const SelectedFeelingsProvider: React.FC<any> = (props) => {
  const defaultSelectedFeelings: SelectedFeeling[] = [];
  const [selectedFeelings, setSelectedFeelings] = useState(
    defaultSelectedFeelings
  );
  return (
    <SelectedFeelingsContext.Provider
      value={{ selectedFeelings, setSelectedFeelings }}
      {...props}
    />
  );
};

const useSelectedFeelings = () => {
  return useContext(SelectedFeelingsContext);
};

export default SelectedFeelingsProvider;
export {
  SelectedFeelingsContext,
  SelectedFeelingsProvider,
  useSelectedFeelings,
};
