import React from "react";
import { Explore, Identify } from "./pages";
import { useRoutes } from "hookrouter";
import { SelectedFeelingsProvider } from "./shared/hooks/use-selected-feelings";

const routes = {
  "/": () => <Explore />,
  "/identify": () => <Identify />,
  "/explore": () => <Explore />,
};

const App = () => {
  const currentRoute = useRoutes(routes);
  return <SelectedFeelingsProvider>{currentRoute}</SelectedFeelingsProvider>;
};

export default App;
