import React from "react";
import { Container, Row, Col } from "reactstrap";
import cx from "classnames";
import styles from "./layout.module.scss";
import Logo from "../logo";
import { A } from "hookrouter";

interface Props {
  className?: string;
}

const Layout: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <Container className={cx(styles.layout, className)} {...rest}>
      <Row>
        <Col>
          <A href="/">
            <Logo width={16 * 15} />
          </A>
        </Col>
        <Col className="d-md-flex justify-content-end align-items-end mt-3 mt-md-0">
          <A href="/" className="ml-3">
            Explore
          </A>
          <A href="/identify" className="ml-3">
            Identify
          </A>
        </Col>
      </Row>
      {children}
    </Container>
  );
};

export default Layout;
