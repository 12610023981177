import React from "react";
import styles from "./feeling.module.scss";
import cx from "classnames";

interface Props {
  isSelected?: boolean;
  baseColor?: string;
  textColor?: string;
  bgColor?: string;
  borderColor?: string;
  onClick: () => void;
}

const Feeling: React.FC<Props> = ({
  isSelected,
  baseColor,
  onClick,
  children,
}) => {
  const textColor = isSelected ? "white" : baseColor;
  const bgColor = isSelected ? baseColor : "white";

  return (
    <div
      className={cx(styles.feeling, {
        [styles.feelingSelected]: isSelected,
      })}
      style={{
        backgroundColor: bgColor,
        outlineColor: baseColor,
        color: textColor,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Feeling;
